import JSBeautifyComponent from "@jordibosch20/software_tools_package/dist/pages/JSBeautify.component.js"
import styles from "./App.module.css";
import { getJSBeautifyString } from "@jordibosch20/software_tools_package/dist/utils/js-beautify";
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
      <div className={styles.App}>
        <JSBeautifyComponent title={"JS beautify"} f={getJSBeautifyString}></JSBeautifyComponent>
      </div>
      <div className={styles.text}>
        <h3>Introduction</h3>
        <p>Debugging JavaScript can often be a daunting task, especially when dealing with minified and uglified code. Compilers and build tools typically minify JavaScript to reduce file size and boost load times, but this often makes the code hard to read and debug. Having a way to quickly reformat this code into a readable format can be a game-changer for developers.</p>
        <h3>Introducing Our Tool: The Instant JavaScript Beautifier</h3>
        <p>Imagine the convenience of transforming any cluttered, minified JavaScript into a well-organized and optimized script with the mere click of a button. That's precisely the capability our JavaScript beautifier provides. No longer must you navigate through dense, compressed code or puzzle over which function belongs to which block. Our tool simplifies the task for you, ensuring your JavaScript is not only easier to understand but also fine-tuned for performance.</p>
        <h4>Key Features:</h4>
        <ul>
        <li><strong>One-Click Transformation:</strong> Paste your JavaScript, click beautify, and instantly see clean, readable code.</li>
        <li><strong>Customization:</strong> Modify the output to align with your coding preferences, including indentation and syntax style.</li>
        </ul>
        <h3>How to Use the Instant JavaScript Beautifier</h3>
        <ol>
        <li><strong>Copy and Paste:</strong> Begin by copying your minified or convoluted JavaScript code into the beautifier's input section.</li>
        <li><strong>Customize Settings:</strong> Select your desired formatting options, such as indentation style or syntax preferences.</li>
        <li><strong>Beautify:</strong> With a simple click, transform your code into a tidy, optimized format.</li>
        <li><strong>Review and Implement:</strong> Examine the beautified script for any necessary changes, then integrate it seamlessly back into your project.</li>
        </ol>
        <h3>Can I Paste Confidential Code?</h3>
        <div>
        <div><span>Be assured, your pasted text remains within your browser. Our tool operates entirely client-side, with no server communication, ensuring your data does not leave your device.</span></div>
        </div>
        <p><strong>Can the Beautifier Handle Large JavaScript Files?</strong><br />A: Without a doubt! Our tool is crafted to manage JavaScript files of any size, from tiny snippets to massive libraries, promoting efficient processing and optimization. It will be able to handle really big files if your device computing power allows it!</p>
        <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;